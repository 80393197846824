/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "./assets/loading4.gif";
//import IcoPdf from "./assets/ico_pdf.png";
import IcoAgenda from "./assets/agenda.ico";
//import Logo from "./assets/DreyfusLogoSite.png";
//import LineBar from "./assets/giphy.gif";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './gerCotacoes.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
//import * as jsPDF from 'jspdf';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { scrollTo } from 'scroll-js';
//import { tr } from "date-fns/esm/locale";
//import gerContrato from "./gerContrato";
import copycli from "copy-to-clipboard";
import PHE from "print-html-element";
//import Countdown from "react-countdown";
//import { date } from "faker";

//import * as jsPDF from 'jspdf'
//import {saveAs} from "file-saver";
//import Embed from 'react-embed';
//import { data } from "jquery";

//var gGlobal = require('./components/globalvar.js');


var TxtSel = "";
//const clipboardy = require('clipboardy');

//import html2canvas from "html2canvas";
//import { func } from "prop-types";
//import { ReadStream } from "tty";

//import '@fortawesome/fontawesome-free/css/all.min.css';
//import "bootstrap-css-only/css/bootstrap.min.css";
//import "mdbreact/dist/css/mdb.css";
//import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon, MDBBadge, MDBContainer, MDBRow, MDBCol} from "mdbreact";


//const modar = require('bootstrap');


//import Form from "./Form";


const dateFormat = require("dateformat");


global.makeChave = function (length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$@';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function MenuAction(){

  let mmenu = document.getElementById('body_base');
  if(mmenu.classList.contains('sidebar-open')){

    mmenu.classList.remove('sidebar-open');
    mmenu.classList.add('sidebar-collapse');

  }

}

        //const deskW = window.innerWidth;



function Gerador() {
  const [rep, setrep] = useState([]);
  const [reprop, setreprop] = useState([]);
  const [Atu, setAtu] = useState([]);
  const [ViewPdf, setViewPdf] = useState([]);
  const [sendMsgEmail, setsendMsgEmail] = useState([]);
  const [solicitaChaveAV, setsolicitaChaveAV] = useState([]);
  const isFirstRun = useRef(true);
  const isFirstRun2 = useRef(true);
  const isFirstRun3 = useRef(true);
  const isFirstRun4 = useRef(true); //solicitaChaveAV
  const isFirstRun5 = useRef(true);
  const isFirstRun6 = useRef(true);
  const isFirstRun7 = useRef(true);
  const isFirstRun8 = useRef(true);
  global.isQuestionKey = '';

  if (window.innerWidth < 640){
    let mmenu = document.getElementById('body_base');
    mmenu.classList.remove('sidebar-open');
    mmenu.classList.add('sidebar-collapse');
    //alert('tamanho '+window.innerWidth);  
  }

  //useEffects

  useEffect(() => {
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '4326356426542564563465463445');
      bodyFormData.set('doc', global.gDoc);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        url: global.gLinkBase+'/rest.php?apikey=9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA',
        //url: 'http://jvfapp.ddns.net:3333/listNewContacs',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      // async function fetchdata() {
      //   const resp = await fetch('http://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        const redata = response['data'];
        //cl('redata', redata);

        setrep(redata);
        let eleLoading = document.getElementById('divLoad');
        if(eleLoading){eleLoading.style.display = 'none';}
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [Atu])

  useEffect(() => {
    if (isFirstRun2.current) {
      isFirstRun2.current = false;
      return;
    }
    const { email, assunto, txtmsg } = sendMsgEmail
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('email', email);
      bodyFormData.set('assunto', assunto);
      bodyFormData.set('txtmsg', txtmsg);
      bodyFormData.set('apikey', '4326356426542564563465463445');
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      //cl("sendEmail bodyFormData", bodyFormData);
      const response = await axios({
        method: 'post',

        url: 'http://admrlk/SendEmail',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { };
      // async function fetchdata() {
      //   const resp = await fetch('http://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        // const redata = response['data'];
        //cl("sendEmail", redata);

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [sendMsgEmail])


  useEffect(() => {


  }, [rep])

  useEffect(() => {


  }, [reprop])


  //Funções

  global.formAddNewContactReset = function (params) {
    document.getElementById("formAddNewContact").reset();
    const idcampos = [
      'inputNome',
      'inputCid',
      'inputEmail4',
      'inputFone',
      'inputObs'
    ]
    for (var i = 0; i < idcampos.length; i++) {
      let eNnome = document.getElementById(idcampos[i])
      eNnome.classList.remove("is-valid")
      eNnome.classList.remove("is-invalid")
    }
  }


  function bViewPdf(xcond, xpasta, xfile) {
    setViewPdf(
      {
        'inputNome': '1',

      }

    );
    //cl("alterar")
    let eleIapikey = document.getElementById('iapikey');
    eleIapikey.value = "9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA"
    let eleIcond = document.getElementById('icond');
    eleIcond.value = xcond
    let eleIpasta = document.getElementById('ipasta');
    eleIpasta.value = xpasta
    let eleIarqv = document.getElementById('iarqv');
    eleIarqv.value = xfile
    let eleFvdocs = document.getElementById('formVdocs');

    //eleFvdocs.submit();

    eleIapikey.value = ""
    eleIcond.value = ""
    eleIpasta.value = ""
    eleIarqv.value = ""


  }



  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCid')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function gerContratoLink(params) {

    window.location.href = "#/contratos-gerenciar";


  }
  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }
  function validaAssunto(params) {
    const eNnome = document.getElementById('inputAssunto')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputAssunto = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    im.mask(eNnome);

  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }
  function validaEmail6(params, idinput) {
    const eNemail = document.getElementById('inputEmail6')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail6 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email && params !== 1) { eNemail.value = params.toLowerCase() };

  }

  // function formataCPF(cpf) {
  //   //retira os caracteres indesejados...
  //   cpf = cpf.replace(/[^\d]/g, "");

  //   //realizar a formatação...
  //   return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  // }

  function formataCnpj(Cnpj) {
    //retira os caracteres indesejados...
    Cnpj = Cnpj.replace(/[^\d]/g, "");

    //realizar a formatação...
    return Cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }


  function clicopied(params) {
    let ele = document.getElementById("txtLinkQuest")
    ele.textContent = "Link Copiado, basta colar..."

  }

  function calculaDias(date1, date2) {
    //formato do brasil 'pt-br'
    moment.locale('pt-br');
    //setando data1
    var data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    var data2 = moment(date2, 'DD/MM/YYYY');
    //tirando a diferenca da data2 - data1 em dias
    var diff = data2.diff(data1, 'days');

    return diff;
  }

  const ref = null;

  // function testeModal(params) {



  function modalBase(h1, p) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>{h1}</h1><br></br><br></br>
            <p id='pmodal'><b>{p}</b></p>
            <br></br>
            {/* <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button> */}
            <button
              className="btn bg-gradient-info btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                onClose();
              }}
            >
              Ok
            </button>
          </div>
        );
      }
    });
  }


  function NumberProp(params) {

    let idprop = dateFormat(Date.now(), "ddmmHHMMss/yy");
    return idprop;

  }
//solicitaChaveAV
  useEffect(() => {
    if (isFirstRun4.current) {
      isFirstRun4.current = false;
      return;
    }

    const { key, cond, bl, unid, email, cpf, id_av, av_desc, av_data, av_datav, av_dataf, reenviar, nomecond, id_btn  } = solicitaChaveAV
    //cl("id_btn",id_btn);
    let b_id_btn = document.getElementById(id_btn);
    b_id_btn.style.display = 'none'
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA55');
      bodyFormData.set('key', key);
      bodyFormData.set('cond', cond);
      bodyFormData.set('bl', bl);
      bodyFormData.set('unid', unid);            
      bodyFormData.set('email', email);
      bodyFormData.set('cpf', global.gDoc);
      bodyFormData.set('id_av', id_av);
      bodyFormData.set('av_desc', av_desc);
      bodyFormData.set('av_data', av_data);
      bodyFormData.set('av_datav', av_datav);
      bodyFormData.set('av_dataf', av_dataf);            
      bodyFormData.set('reenviar', reenviar);
      bodyFormData.set('nomecond', nomecond);
      

      const response = await axios({
        method: 'post',

        url: 'https://assembleia.riluk.com.br/api/create_key_ad.php',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) {

       //cl("create_key",response['data'][0]);

        if (response['data'][0] == 'GERADA;CHECADA;EMAIL'){
          let b_id_btn = document.getElementById(id_btn);
          b_id_btn.style.display = '';
          b_id_btn.classList.remove('btn-info');
          b_id_btn.classList.add('btn-success');          
          b_id_btn.innerHTML = 'Email Enviado !';
          global.b_id_btn = 1;
        }

        if (response['data'][0] == 'EXITENTE'){
          let b_id_btn = document.getElementById(id_btn);
          b_id_btn.style.display = '';
          b_id_btn.classList.remove('btn-info');
          b_id_btn.classList.add('btn-danger');
          b_id_btn.innerHTML = 'Já existe a Chave !';
          global.b_id_btnex = 1;

        }

       };
      // async function fetchdata() {
      //   const resp = await fetch('http://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        // const redata = response['data'];
        //cl("sendEmail", redata);

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };


  }, [solicitaChaveAV])

  function GeraLinkAV(id_av,cond,bl,unid,av_desc,av_datainit,av_datav,av_dataf,reenviar,nomecond,id_btn) {
    let key = global.makeChave(20);
    //cl("id_btn gera",id_btn);
    setsolicitaChaveAV(
      {
        'key': key,
        'cond': cond,
        'bl': bl,
        'unid': unid,
        'email': global.gEmail,
        'cpf': global.gDoc,
        'id_av': id_av,
        'av_desc' : av_desc,
        'av_data' : av_datainit,
        'av_datav' : av_datav,
        'av_dataf' : av_dataf,                
        'reenviar' : reenviar,
        'nomecond' : nomecond,
        'id_btn': id_btn
      }
    );
    //cl("key",key);
    
  }

  // function numberParaReal(numero) {
  //   var formatado = "R$ " + numero.toFixed(2).replace('.', ',');
  //   return formatado;
  // }

  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  // function TeclaEnter(params) {

  // }


  function CalcSimule(params) {

    let eleDtIni = document.getElementById('inputDtIni');
    let eleDtFim = document.getElementById('inputDtFim');

    let eleDifDias = document.getElementById('inputDifDias');
    let difDias = calculaDias(dateFormat(eleDtIni.value, "dd/mm/yyyy"), dateFormat(eleDtFim.value, "dd/mm/yyyy"));
    eleDifDias.value = difDias;
    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let M30 = Math.floor(difDias / 30);
    eleMeses30.value = M30;

    let eleTUnids = document.getElementById('inputTUnids');
    let TUnids = eleTUnids.value;

    let eleTDiasPasta = document.getElementById('inputTDiasPasta');
    let TDiasPasta = eleTDiasPasta.value;
    let elePrazo = document.getElementById('inputPrazo');
    let Prazo = (TDiasPasta * M30);
    Prazo = Math.round(Prazo);
    elePrazo.value = Prazo;

    let eleVUnit = document.getElementById('inputVUnit');
    let Vunit = realParaNumber(eleVUnit.value);
    eleVUnit.value = Vunit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleVTotal = document.getElementById('inputVTotal');
    let VTotal = (M30 * Vunit);
    eleVTotal.value = VTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let elePorUnids = document.getElementById('inputPorUnids');
    let PorUnids = (VTotal / TUnids);
    elePorUnids.value = PorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleQParc = document.getElementById('inputQParc');
    let QParc = eleQParc.value;
    let eleVParc = document.getElementById('inputVParc');
    let VParc = (VTotal / QParc);
    eleVParc.value = VParc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPorUnids = document.getElementById('inputParcPorUnids');
    let ParcPorUnids = (VParc / TUnids);
    eleParcPorUnids.value = ParcPorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleTDas = document.getElementById('inputTDas');
    let TDas = (VTotal * 0.06);
    eleTDas.value = TDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcDas = document.getElementById('inputParcDas');
    let ParcDas = (TDas / QParc);
    eleParcDas.value = ParcDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleTPrc = document.getElementById('inputTPrc');
    let TPrc = (VTotal * 0.20);
    eleTPrc.value = TPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPrc = document.getElementById('inputParcPrc');
    let ParcPrc = (TPrc / QParc);
    eleParcPrc.value = ParcPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleLucro = document.getElementById('inputLucro');
    let Lucro = (VTotal - (TDas + TPrc));
    eleLucro.value = Lucro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });


  }

  function CalcDias(params) {

    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let elePrazo = document.getElementById('inputPrazo');
    let eleTDias = document.getElementById('inputTDiasPasta');
    eleTDias.value = (elePrazo.value / eleMeses30.value);



  }

  function rowClick(e, row) {
    ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
    //cl("rowClick id: " + row.getData().id, row, e);
    ////cl(rep);

  };

  function novoContato(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = '';

  }

  function enviarEmail(assunto, email, txtmsg) {
    validaEmail6(document.getElementById('inputEmail6').value, 'inputEmail6')
    if (!global.inpAddFrmC.inputEmail6) { return validaEmail6(1, 'inputEmail6') }
    //cl("enviar email global.inpAddFrmC.inputEmail6 > ", global.inpAddFrmC.inputEmail6)
    //cl("enviar email global.inpAddFrmC > ", global.inpAddFrmC)
    if (global.inpAddFrmC.inputEmail6) {
      setsendMsgEmail(
        {
          'assunto': document.getElementById('inputAssunto').value,
          'email': document.getElementById('inputEmail6').value,
          'txtmsg': document.getElementById('txtEmail').value,
        }

      );
      let ele = document.getElementById('panelEmail');
      ele.style.display = 'none';
      let eleCard = document.getElementById('cardGridBgg');
      eleCard.classList.remove("collapsed-card");
      let eleCardI = document.getElementById('cardGridBggI');
      eleCardI.classList.remove("fa-plus");
      eleCardI.classList.add("fa-minus");
      let eleCardbody = document.getElementById('cardGridBggBody');
      eleCardbody.style.display = '';

      //cl("enviou email")
    }
  }

  function ViewDocs(params) {
    let linkaction = '';
    //{linkaction}

    return (
      <>
        <div className="card card-info cardGridBgg" id="cardGridBgg" >
          <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-superpowers fa-spin fa-4x fa-fw"></i></div>
          <div className="card-body locCont" id="cardGridBggBody">
            {/* <form id="formVdocs" action={linkaction} method="post" >
              <input id="iapikey" type="hidden" name="apikey" value="" />
              <input id="icond" type="hidden" name="cond" value="" />
              <input id="ipasta" type="hidden" name="pasta" value="" />
              <input id="iarqv" type="hidden" name="arqv" value=".pdf" /> */}
              {/* <input id="bSubmit" type="submit" value="submit" formtarget="_blank"/> */}
              {/* <button type="button" className="btn btn-info " id="btnGravar" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>PDF</button>
              <a target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm float-left" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>Link</a> */}
              <section className="content-header">
            <h1 id="TxtSeltxt" className="box-title-bgg"></h1>

                <section className="content" id="divpage5">

                </section>
              </section>
            {/* </form> */}
          </div>

        </div>
      </>

    )
  }

  function nomeAta(nome){
    nome = nome.replace('.pdf','');
    nome = nome.replace(/_/g,' ');

    return nome;
  }

  useEffect(() => {
    // Não executar inicialmente

    // if (isFirstRun8.current) {
    //   isFirstRun8.current = false;
    //   //cl("DelNCtc first3 false")
    //   return;
    // }


    // evitar erro quando não aparecer ainda a div antes do render

    if (!document.getElementById("divpage5")) {
      setViewPdf(
        {
          'inputNome': '1',

        }

      );
      return;

    }

    if (window.innerWidth < 800){
      let mmenu = document.getElementById('body_base');
      mmenu.classList.remove('sidebar-open');
      mmenu.classList.add('sidebar-collapse');
      //alert('tamanho '+window.innerWidth);  
    }

    //cl("DelNCtc first3", isFirstRun3)
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let ignore = false;

    let vHNomeCond = document.getElementById('Hnomecond');
    vHNomeCond.innerHTML = global.gNomecond;

    //const { chavedel, propdel } = DelProp;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA');
      bodyFormData.set('doc', global.gListCond);
     //cl("listCond",global.gListCond);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response8 = await axios({
        method: 'post',

        url: 'https://assembleia.riluk.com.br/api/list_ad.php',
        //url: 'http://jvfapp.ddns.net:3333/listNewContacs',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response8) {

        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; }
       //cl("resp data 8", response8['data']);


        const respprop = response8['data'];
        var element = document.getElementById("divpage5");
        //limpar div
        element.innerHTML = "";
        //cl("resprop",respprop);
        if (respprop['ad']) {
          const listItems = respprop['ad'].sort();
          
          txtTxtSel("Lista de Assembleias agendadas :");

          const divRow = document.createElement("div");
          divRow.setAttribute('class','row ')

          listItems.forEach(function (item) {
          //   <div className="row" >
          //   <div className="col-md-8 col-sm-6 col-xs-12">
          //     <div className="info-box" >
          //       <span className="info-box-icon bg-aqua" ><i class="fa fa-envelope-o"></i></span>
          //       <div className="info-box-content">
          //         <span className="info-box-number">1,410</span>
          //       </div>
          //     </div>
          //   </div>
          // </div>

            const div0 = document.createElement("div");
            div0.setAttribute('class','info-box col-md-4 col-sm-4 col-xs-4')
            div0.setAttribute('name','div0')
          
            const div2 = document.createElement("div");
            div2.setAttribute('class','col-md-12 col-sm-12 col-xs-12')
            div2.setAttribute('name','div2')

            const div3 = document.createElement("div");
            div3.setAttribute('class','info-box')
            div3.setAttribute('name','div3')

            const span3 = document.createElement("span");
            span3.setAttribute('class','info-box-icon bg-aqua')
            span3.setAttribute('name','span3')

            const oImg = document.createElement("IMG");
            oImg.setAttribute('src', IcoAgenda);
            oImg.setAttribute('alt', 'na');
            oImg.setAttribute('height', '42px');
            oImg.setAttribute('width', '42px');

            span3.appendChild(oImg);
            div3.appendChild(span3);

            const div4 = document.createElement("div");
            div4.setAttribute('class','info-box-content-bgg');
            //cl("item0",item);

            const span4 = document.createElement("p");
            span4.setAttribute('class','bgg_p_lav_t');
            let txtp = global.gaListCond[item["cond"]]["nome"];
            txtp = txtp.replace("CONDOMÍNIO","COND");
            txtp = txtp.replace("EDIFÍCIO","ED");
            txtp = txtp.replace("CONDOMINIO","COND");
            txtp = txtp.replace("EDIFICIO","ED");
            txtp = txtp.replace("RESIDENCIAL","RES");
            txtp = txtp.replace("ADMINISTRAÇÃO","ADM");
            const node = document.createTextNode(txtp);
            span4.appendChild(node);
            div4.appendChild(span4);

            const span5 = document.createElement("p");
            span5.setAttribute('class','bgg_p_lav');
            const txtp2 = item["desc"];
            const node2 = document.createTextNode(txtp2);
            span5.appendChild(node2);
            div4.appendChild(span5);
            
            const span6 = document.createElement("p");
            span6.setAttribute('class','bgg_p_lav_dt');
            //const txtp3 = dateFormat(item["datainit"], "dd/mm/yyyy hh:MM")+'h ';
            const txtp3 = moment(item["datainit"]).format("DD/MM/YYYY HH:mm") +'h ';
            const node3 = document.createTextNode(txtp3);
            span6.appendChild(node3);
            div4.appendChild(span6);            

            div3.appendChild(div4);
            div2.appendChild(div3);
            div0.appendChild(div2)
            divRow.appendChild(div0);

            //<button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={CloseaddNewContact}>Fechar</button>
            const btn_particp = document.createElement("button");
            btn_particp.setAttribute('class','btn btn-info btn-block btnbggp');
            let id_btn = 'btn_particp'+item["id"];
            //cl("id_btn1",id_btn) ;          
            btn_particp.setAttribute('id',id_btn);
            //const click_btn_particp = "whatsappweb()";
            //btn_particp.setAttribute('onClick', click_btn_particp);
            //cl("verifica data",dateFormat(item["dataagora"], "dd/mm/yyyy hh:MM")); //HH
            //if( (dateFormat(item["datalink"], "dd/mm/yyyy HH:MM")) < (dateFormat(item["dataagora"], "dd/mm/yyyy HH:MM"))){
            if( (moment(item["datalink"])) < (moment(item["dataagora"]))){

              btn_particp.innerHTML = "Solicitar Acesso";

              btn_particp.addEventListener ("click", function() {

                if((!global.b_id_btnex) && (!global.b_id_btn)){

                  let nomecondOK = global.gaListCond[item["cond"]]["nome"];
                  nomecondOK = nomecondOK.replace("CONDOMÍNIO","COND");
                  nomecondOK = nomecondOK.replace("EDIFÍCIO","ED");
                  nomecondOK = nomecondOK.replace("CONDOMINIO","COND");
                  nomecondOK = nomecondOK.replace("EDIFICIO","ED");
                  nomecondOK = nomecondOK.replace("RESIDENCIAL","RES");
                  nomecondOK = nomecondOK.replace("ADMINISTRAÇÃO","ADM");
                  //cl("id_btn2",id_btn) ;         
              
                  GeraLinkAV(item["id"],item["cond"],global.gaListCond[item["cond"]]["bl"],global.gaListCond[item["cond"]]["unid"],item["desc"],item["datainit"],item["datavotacao"],item["dataend"],0,nomecondOK,id_btn);
                }
              });

            } else {

              //btn_particp.innerHTML = 'Solicitar após '+ (dateFormat(item["datalink"], "dd/mm/yyyy hh:MM"));
              btn_particp.innerHTML = 'Solicitar após '+ (moment(item["datalink"]).format("DD/MM/YYYY HH:mm")) + 'h';

              btn_particp.addEventListener ("click", function() {
                modalBase("Volte depois por favor","A Solicitação do Link será liberada no dia "+(dateFormat(item["datalink"], "dd/mm/yyyy hh:MM"))+"h");
              });

            }


            div2.appendChild(btn_particp);
            


            //cl('item',item);
            const paraA = document.createElement("a");
            const parabr = document.createElement("p");
            //   const paraI = document.createElement("i");
            //   paraI.setAttribute('class', 'far fa-file-word');
            
            //const linkrrl = bViewPdf(global.gCond,'ATAS',item[0])
            const pasta = "EDITAIS";
            //const linkurl2 = "bViewPdf(\'" + global.gCond + '\',\'' + pasta + '\',\'' + item[0] + "\'); return false;";

            paraA.onClick = "console.log('teste');";
            paraA.target = "_blank";
            paraA.setAttribute('rel', 'noopener noreferrer');
            paraA.setAttribute('class', 'btn btn-block btn-social btn-facebook');
            //paraA.setAttribute('href', '');

            global.bgg2 = function (cond, pasta, file) {
              //console.log(cond,pasta,file);
              //bViewPdf(cond, pasta, file);
              //return;
            }

            const linkclick = "bgg2(\'" + global.gCond + '\',\'' + pasta + '\',\'' + item[0] + "\')";
            paraA.setAttribute('onClick', linkclick);
            //paraA.appendChild(oImg);
            //parabr.appendChild(paraA);
            
            //paraA.appendChild(divRow);
            //element.appendChild(paraA);

            //desativado paraA acima
            element.appendChild(divRow);
          });

        } else {
          
          txtTxtSel("Nenhuma Assembleia agendada !");

        }

      };

      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        //setListProp({ 'chave': chavedel });
        //cl('setatu 01 01')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [ViewPdf])



  function txtTxtSel(a){
    let txtC = document.getElementById('TxtSeltxt');
    txtC.textContent = a;
  }

  function geraWordProp(chave, prop) {
    const linkurl = "http://admriluk/docx.php?apikey=398738497834758934759834758934&chave=" + chave + "&prop=" + prop;
    window.open(linkurl, '_blank');

  }

  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }


  function copiarlinha(linha) {
    copycli(linha, {
      debug: true,
      message: 'Press #{key} to copy',
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Linha digitável copiada !!</h1><br></br><br></br>
            <p id='pmodal'><b>{linha}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Ok</button>
          </div>
        );
      }
    });
    //clipboardy.writeSync(linha);
  }


  const printIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-print'></i>";
  };
  const iconwhatsappweb = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-whatsapp'></i>";
  }
  const iconcopy = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-copy'></i>";
  }
  const iconLixeira = function (cell, formatterParams) { //plain text value
    if (cell.getRow().getData().progress < 11) {
      return "<i class='fa fa-trash-o'></i>";
    } else {
      return "-";
    }
  }
  const emailIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-envelope-o'></i>";
  };
  const infoIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-info-circle'></i> " + cell.getRow().getData().msginfo;
  };
  const wordIcon = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-file-word'></i>";
  };
  const iconContrato = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-plus-square'></i>";
  };
  const iconBarcode = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-eye'></i>";
  };

  const formatPrazo = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getValue(), "dd/mm/yyyy"));
    if (cell.getValue() == null) {
      return "-"; //return the contents of the cell;  
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias < 3)) {
      return "<font color='red'><b>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</b></font>";
    } else if ((cell.getRow().getData().qtdProp > 0)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias > 3)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    }
  }

  const formatTdifdias = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getRow().getData().prazo, "dd/mm/yyyy"));
    if ((cell.getRow().getData().qtdProp < 1) && (cell.getRow().getData().prazo !== null)) {
      if (tdifdias >= 4) {
        return "<font color='green'>" + tdifdias + "</font>";
      } else if ((tdifdias > 0) && (tdifdias <= 3)) {
        return "<font color='red'>" + tdifdias + "</font>";
      } else if ((tdifdias <= 0)) {
        return "<font color='red'><b>" + tdifdias + "</b></font>";
      }
    } else {
      return "";
    }

  }


  if (rep.length < 1) {
    return (
      <div className="content-wrapper">
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando dados...</div>
      </div>
    )
  } else {
    return (
      <div className="content-wrapper">
        {ViewDocs()}
        {/* {page1()} */}
        {/* {page2()} */}
        {/* {page3()} */}
        {/* {page4()} */}
        {/* {page5()} */}
      </div>
    )

  }
}

export default Gerador