/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import Loading from "./assets/loading4.gif";
import IcoPdf from "./assets/ico_pdf.png";
//import Logo from "./assets/DreyfusLogoSite.png";
//import LineBar from "./assets/giphy.gif";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { cl } from "./components/functions";
import './gerCotacoes.css';
import './viPastaDPdf.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import '../node_modules/moment/locale/pt-br';
//import * as jsPDF from 'jspdf';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { scrollTo } from 'scroll-js';
//import { tr } from "date-fns/esm/locale";
//import gerContrato from "./gerContrato";
import copycli from "copy-to-clipboard";
import PHE from "print-html-element";

//import * as jsPDF from 'jspdf'
//import {saveAs} from "file-saver";
//import Embed from 'react-embed';
//import { data } from "jquery";

var gGlobal = require('./components/globalvar.js');
//const clipboardy = require('clipboardy');

//import html2canvas from "html2canvas";
//import { func } from "prop-types";
//import { ReadStream } from "tty";

//import '@fortawesome/fontawesome-free/css/all.min.css';
//import "bootstrap-css-only/css/bootstrap.min.css";
//import "mdbreact/dist/css/mdb.css";
//import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon, MDBBadge, MDBContainer, MDBRow, MDBCol} from "mdbreact";


//const modar = require('bootstrap');


//import Form from "./Form";


const dateFormat = require("dateformat");


global.makeChave = function (length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


function Gerador() {
  const [rep, setrep] = useState([]);
  const [reprop, setreprop] = useState([]);
  const [Atu, setAtu] = useState([]);
  const [AprovList, setAprovList] = useState([]);
  const [Aprov, setAprov] = useState([]);
  const [Ressalva, setRessalva] = useState([]);

  const [ViewPdf, setViewPdf] = useState([]);
  //add050523
  const [SelGCond, setSelGCond] = useState([]);
  const isFirstRun = useRef(true);
  const isFirstRun2 = useRef(true);
  const isFirstRun3 = useRef(true);

  global.isQuestionKey = '';

  useEffect(() => {
    if (isFirstRun2.current) {
      isFirstRun2.current = false;
      //cl("DelNCtc first3 false")
      return;
    }
    //cl("DelNCtc first3", isFirstRun3)

    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let ignore = false;
    const { nomefile, bytestxt } = Aprov;

    if ((!global.nomeFileAprov) || (global.nomeFileAprov === '') || (global.nomeFileAprov === 'undefined') || (global.nomeFileAprov === null)) {
      cl("clglobal.nomeFileAprov", global.nomeFileAprov);
      return
    }
    if ((!bytestxt) || (bytestxt === '') || (bytestxt === 'undefined') || (bytestxt === null)) {
      cl("clbytestxt", bytestxt);
      return
    }
    async function fetchAprov() {

      var bodyFormData = new FormData();
      bodyFormData.set('cond', global.gCond);
      bodyFormData.set('nomecond', global.gNomecondAbr);
      bodyFormData.set('nomefile', global.nomeFileAprov);
      bodyFormData.set('bytestxt', bytestxt);
      bodyFormData.set('cpftxt', global.gDoc);
      bodyFormData.set('ip', global.gIp);
      bodyFormData.set('aprovado', 1);
      bodyFormData.set('nome', global.gNome);
      bodyFormData.set('email', global.gEmail);
      bodyFormData.set('chave', 'gy34gsddfy45y45');
      cl("bodyFormData",bodyFormData)
      cl("foi")


      const respAprov = await axios({
        method: 'post',

        url: global.gLinkBaseApi + '/apdig.php?apikey=9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA651901890189104',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("clrespFilesAprov['data']",respFilesAprov['data']);
      global.nomeFileAprov = '';
      if (respAprov['data']) {
        //cl("clrespAprov",respAprov['data']);
        setAprovList({ '0': '0' });

      }
      if (eleLoading) { eleLoading.style.display = 'none'; }

    }
    fetchAprov();
    return () => { ignore = true };
  }, [Aprov])

  useEffect(() => {
    if (isFirstRun2.current) {
      isFirstRun2.current = false;
      //cl("DelNCtc first3 false")
      return;
    }
    //cl("DelNCtc first3", isFirstRun3)

    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let ignore = false;
    const { nomefile, bytestxt } = Ressalva;


    if ((!global.gMsgRessalva) || (global.gMsgRessalva === '') || (global.gMsgRessalva === 'undefined') || (global.gMsgRessalva === null)) {
      return
    }
    if ((!global.nomeFileAprov) || (global.nomeFileAprov === '') || (global.nomeFileAprov === 'undefined') || (global.nomeFileAprov === null)) {
      cl("clglobal.nomeFileAprov", global.nomeFileAprov);
      return
    }
    async function fetchAprov() {

      var bodyFormData = new FormData();
      bodyFormData.set('cond', global.gCond);
      bodyFormData.set('nomecond', global.gNomecondAbr);
      bodyFormData.set('nomefile', global.nomeFileAprov);
      bodyFormData.set('bytestxt', bytestxt);
      bodyFormData.set('cpftxt', global.gDoc);
      bodyFormData.set('ip', global.gIp);
      bodyFormData.set('aprovado', 2);
      bodyFormData.set('nome', global.gNome);
      bodyFormData.set('email', global.gEmail);
      bodyFormData.set('msg',  encodeURIComponent(global.gMsgRessalva));
      bodyFormData.set('chave', 'gy34gsddfy45y45');


      const respAprov = await axios({
        method: 'post',

        url: global.gLinkBaseApi + '/apdig.php?apikey=9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA651901890189104',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      cl("clrespAprov",respAprov);
      global.nomeFileAprov = '';
      global.gMsgRessalva = '';
      if (respAprov['data']) {
        //cl("clrespAprov",respAprov['data']);
        setAprovList({ '0': '1' });

      }
      if (eleLoading) { eleLoading.style.display = 'none'; }

    }
    fetchAprov();
    return () => { ignore = true };
  }, [Ressalva])


  useEffect(() => {
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '4326356426542564563465463445');
      bodyFormData.set('doc', global.gDoc);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        url: global.gLinkBase + '/rest.php?apikey=9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA',
        //url: 'https://jvfapp.ddns.net:3333/listNewContacs',
        // url: 'https://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        const redata = response['data'];
        //cl('redata', redata);

        setrep(redata);
        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; }
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [Atu])


  //add050523

  useEffect(() => {


    async function fetchdata() {
      if (global.gaListCondDistinct.length > 1) {

        let eleCardbody = document.getElementById('cardGridBggBody');
        if (eleCardbody) { eleCardbody.style.display = 'none'; }
        let eselectConddivPD = document.getElementById('selectConddivPD');
        if (eselectConddivPD) {

          let arrFound = gaListCondDistinct.filter(function (item, num) {
            return item.id === Number(eselectConddivPD.value);
          });

          //cl("clgaListCondDistinct", gaListCondDistinct);
          //cl("clarrFound", arrFound);

          global.gCond = arrFound[0].cond;

          listViewPdf();


        }

      } else {
        return
      }
    }
    fetchdata();


  }, [SelGCond])




  useEffect(() => {


  }, [rep])

  useEffect(() => {


  }, [reprop])


  //Funções

  global.formAddNewContactReset = function (params) {
    document.getElementById("formAddNewContact").reset();
    const idcampos = [
      'inputNome',
      'inputCid',
      'inputEmail4',
      'inputFone',
      'inputObs'
    ]
    for (var i = 0; i < idcampos.length; i++) {
      let eNnome = document.getElementById(idcampos[i])
      eNnome.classList.remove("is-valid")
      eNnome.classList.remove("is-invalid")
    }
  }



  function bViewPdf(xcond, xpasta, xfile) {
    setViewPdf(
      {
        'inputNome': '1',

      }

    );
    //cl("alterar")
    let eleIapikey = document.getElementById('iapikey');
    eleIapikey.value = "9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA"
    let eleIcond = document.getElementById('icond');
    eleIcond.value = xcond
    let eleIpasta = document.getElementById('ipasta');
    eleIpasta.value = xpasta
    let eleIarqv = document.getElementById('iarqv');
    eleIarqv.value = xfile
    let eleFvdocs = document.getElementById('formVdocs');
    eleFvdocs.submit();
    eleIapikey.value = ""
    eleIcond.value = ""
    eleIpasta.value = ""
    eleIarqv.value = ""


  }

  function listViewPdf(params) {
    setViewPdf(
      {
        'inputNome': '1',

      }

    );


  }


  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCid')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }
  function validaAssunto(params) {
    const eNnome = document.getElementById('inputAssunto')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputAssunto = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    im.mask(eNnome);

  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }
  function validaEmail6(params, idinput) {
    const eNemail = document.getElementById('inputEmail6')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail6 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email && params !== 1) { eNemail.value = params.toLowerCase() };

  }


  function formataCnpj(Cnpj) {
    //retira os caracteres indesejados...
    Cnpj = Cnpj.replace(/[^\d]/g, "");

    //realizar a formatação...
    return Cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }


  function clicopied(params) {
    let ele = document.getElementById("txtLinkQuest")
    ele.textContent = "Link Copiado, basta colar..."

  }

  function calculaDias(date1, date2) {
    //formato do brasil 'pt-br'
    moment.locale('pt-br');
    //setando data1
    var data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    var data2 = moment(date2, 'DD/MM/YYYY');
    //tirando a diferenca da data2 - data1 em dias
    var diff = data2.diff(data1, 'days');

    return diff;
  }




  const ref = null;


  function bggcheck(params) {
    const ele = document.getElementById('txtRetira')
    if (ele.checked === true) {
      ele.value = "1";
    } else {
      ele.value = "0";
    }
    //const result = ele.value;
    //console.log('checkbox', result) ;

  }

  function removeOptions(selectElement) {
    if (!selectElement) {
      return
    }

    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }

  function abreviaNomeCond(params) {

    params = params.replace("CONDOMÍNIO", "COND.");
    params = params.replace("EDIFÍCIO", "ED.");
    params = params.replace("CONDOMINIO", "COND.");
    params = params.replace("EDIFICIO", "ED.");
    params = params.replace("RESIDENCIAL", "RES.");
    params = params.replace("ADMINISTRAÇÃO", "ADM.");

    return params

  }
  function modalBase(h1, p) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>{h1}</h1><br></br><br></br>
            <p id='pmodal'><b>{p}</b></p>
            <br></br>
            {/* <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button> */}
            <button
              className="btn bg-gradient-info btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                onClose();
              }}
            >
              Ok
            </button>
          </div>
        );
      }
    });
  }


  function NumberProp(params) {

    let idprop = dateFormat(Date.now(), "ddmmHHMMss/yy");
    return idprop;

  }

  // function numberParaReal(numero) {
  //   var formatado = "R$ " + numero.toFixed(2).replace('.', ',');
  //   return formatado;
  // }

  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }



  function rowClick(e, row) {
    ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
    //cl("rowClick id: " + row.getData().id, row, e);
    ////cl(rep);

  };


  function ViewDocs(params) {
    let linkaction = global.gLinkBase + '/vdocs_dw.php';

    // cl("clglobal.gLinkBase",global.gLinkBase);
    // cl("clglobal.gLinkBaseApi",global.gLinkBaseApi);
    //{linkaction}

    return (
      <>
        <div className="card card-info cardGridBgg" id="cardSelCond" >
          <div id="divLoad" className="load" style={{ display: '' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          <div className="card-body locCont" id="cardSelCondBody">

            <section className="content-header">
              <h1 className="box-title-bgg">Condomínio selecionado</h1>
              <section className="content" id="divSelCond5">

                <select id="selectConddivPD" onChange={() => validaSelectCond()}>
                </select>
              </section>
            </section>
          </div>

        </div>
        <div className="card card-info cardGridBgg" id="cardGridBgg" >
          <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          <div className="card-body locCont" id="cardGridBggBody">
            <form id="formVdocs" action={linkaction} method="post" >
              <input id="iapikey" type="hidden" name="apikey" value="" />
              <input id="icond" type="hidden" name="cond" value="" />
              <input id="ipasta" type="hidden" name="pasta" value="" />
              <input id="iarqv" type="hidden" name="arqv" value=".pdf" />
              {/* <input id="bSubmit" type="submit" value="submit" formtarget="_blank"/> */}
              {/* <button type="button" className="btn btn-info " id="btnGravar" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>PDF</button>
              <a target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm float-left" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>Link</a> */}
              <section className="content-header">
                <h1 id="TxtSeltxt" className="box-title-bgg">Selecione a Pasta Digital abaixo para realizar o download (pdf)</h1>

                <section className="content" id="divpage5">

                </section>
              </section>
            </form>
          </div>

        </div>
      </>

    )
  }

  function nomeAta(nome) {
    nome = nome.replace('.pdf', '');
    nome = nome.replace('balancete', '');
    nome = nome.replace(/_/g, ' ');
    // let tipo = nome.substring(0, 3);
    //cl("clnome",nome);
    let ano = nome.substring(4, 8);
    let mes = nome.substring(0, 3);
    // let dia = nome.substring(12,14);

    // if (tipo=='AGO') {
    //   tipo = 'Assembléia Geral Ordinária realizada em '
    // }

    // if (tipo=='AGE') {
    //   tipo = 'Assembléia Geral Extraordinária realizada em  '
    // }

    // let result = tipo+' '+dia+'/'+mes+'/'+ano;


    // if (nome.length > 14) {
    //   result = result +' ('+nome.substring(15,40)+')';
    // }
    nome = 'ref.: ' + mes + '/' + ano;

    return nome;
  }

  function nomeBtnAprovar(nome) {
    nome = nome.replace('.pdf', '');
    nome = nome.replace('balancete', '');
    nome = nome.replace(/_/g, ' ');
    // let tipo = nome.substring(0, 3);
    //cl("clnome",nome);
    let ano = nome.substring(4, 8);
    let mes = nome.substring(0, 3);
    // let dia = nome.substring(12,14);

    // if (tipo=='AGO') {
    //   tipo = 'Assembléia Geral Ordinária realizada em '
    // }

    // if (tipo=='AGE') {
    //   tipo = 'Assembléia Geral Extraordinária realizada em  '
    // }

    // let result = tipo+' '+dia+'/'+mes+'/'+ano;


    // if (nome.length > 14) {
    //   result = result +' ('+nome.substring(15,40)+')';
    // }
    nome = mes + '/' + ano;

    return nome;
  }

  useEffect(() => {

    async function fetchAprovList() {

      var bodyFormData = new FormData();
      bodyFormData.set('cond', global.gCond);
      bodyFormData.set('nomecond', global.gNomecondAbr);
      bodyFormData.set('nomefile', global.nomeFile);
      bodyFormData.set('bytestxt', global.bytesFile);
      bodyFormData.set('cpftxt', global.gDoc);
      bodyFormData.set('ip', global.gIp);
      bodyFormData.set('aprovado', 0);
      bodyFormData.set('chave', 'gy34gsddfy45y45');


      const respFilesAprov = await axios({
        method: 'post',

        url: global.gLinkBaseApi + '/apdig.php?apikey=9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA651901890189104',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("clrespFilesAprov['data']",respFilesAprov['data']);
      if (respFilesAprov['data']) {
        global.grespFilesAprov = respFilesAprov['data'];
        //cl("clglobal.grespFilesAprov", global.grespFilesAprov);
        setViewPdf(
          {
            'inputNome': '1',

          }

        );

      }
    }
    fetchAprovList();

  }, [AprovList])

  useEffect(() => {
    // Não executar inicialmente

    // if (isFirstRun8.current) {
    //   isFirstRun8.current = false;
    //   //cl("DelNCtc first3 false")
    //   return;
    // }


    // evitar erro quando não aparecer ainda a div antes do render

    if (!document.getElementById("divpage5")) {
      setViewPdf(
        {
          'inputNome': '1',

        }

      );
      return;

    }
    //cl("DelNCtc first3", isFirstRun3)
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let ignore = false;
    let vHNomeCond = document.getElementById('Hnomecond');
    vHNomeCond.innerHTML = global.gNomecond;

    //const { chavedel, propdel } = DelProp;

    if (window.innerWidth < 800) {
      let mmenu = document.getElementById('body_base');
      mmenu.classList.remove('sidebar-open');
      mmenu.classList.add('sidebar-collapse');
      //alert('tamanho '+window.innerWidth);  
    }



    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9998BG9GSN3B8UNYaVNHHESVENB89YHE8NA');
      bodyFormData.set('doc', global.gCond);
      bodyFormData.set('cargo', global.gCargo);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response8 = await axios({
        method: 'post',

        url: global.gLinkBase + '/docs.php',
        //url: 'https://jvfapp.ddns.net:3333/listNewContacs',
        // url: 'https://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response8) {


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; }
        //cl("resp data 8", response8['data']);


        const respprop = response8['data'];
        var element = document.getElementById("divpage5");
        //limpar div
        element.innerHTML = "";

        //add050523
        let eselectCond = document.getElementById('selectConddivPD');
        if (eselectCond) {
          eselectCond.style.display = 'none';
          removeOptions(eselectCond);
          /*             let opt = document.createElement('option');
                      opt.value = 0;
                      opt.innerHTML = 'Selecione...';
                      eselectCond.appendChild(opt);
           */
          let k;
          for (k = 0; k < (global.gaListCondDistinct.length); k++) {
            let opt = document.createElement('option');
            opt.value = global.gaListCondDistinct[k].id;
            opt.innerHTML = abreviaNomeCond(global.gaListCondDistinct[k].nome);
            eselectCond.appendChild(opt);

          }
          let arrFound = gaListCondDistinct.filter(function (item, num) {
            return item.cond === global.gCond;
          });

          //cl("clarrFound",arrFound);
          eselectCond.value = arrFound[0].id;
          //cl("clglobal.gNomecond",global.gNomecond);
          global.gNomecond = arrFound[0].nome;
          global.gNomecondAbr = abreviaNomeCond(arrFound[0].nome);
          //cl("clglobal.gNomecondRevisado",global.gNomecond);
          //cl("clglobal.gNomecondAbr",global.gNomecondAbr);


          if (eselectCond) { eselectCond.style.display = ''; };
        }

        if (respprop['PD']) {
          txtTxtSel("Selecione a Pasta Digital abaixo para realizar o download (pdf)");
          const listItems = respprop['PD'];
          cl("cllistItems",listItems);


          listItems.forEach(function (item) {

            //   <div className="row" >
            //   <div className="col-md-8 col-sm-6 col-xs-12">
            //     <div className="info-box" >
            //       <span className="info-box-icon bg-aqua" ><i class="fa fa-envelope-o"></i></span>
            //       <div className="info-box-content">
            //         <span className="info-box-number">1,410</span>
            //       </div>
            //     </div>
            //   </div>
            // </div>

            let arrItem1 = item[1].split(",");
            //cl("clitem0", item[1];
            //cl("clarrItem1_0", arrItem1[0]);
            //cl("clarrItem1_1", arrItem1[1]);
            let item1data = arrItem1[0]
            let item1size = arrItem1[1]
            let item0nome = item[0]

            const divRow = document.createElement("div");
            divRow.setAttribute('class', 'row ')

            const divdfile = document.createElement("div");
            divdfile.setAttribute('class', 'dfile ')

            const divdbtn = document.createElement("div");
            divdbtn.setAttribute('class', 'dbtn col-md-12 col-sm-12 col-xs-12')
            divdbtn.setAttribute('id', 'dbtn ')

            const divitem = document.createElement("div");
            divitem.setAttribute('class', 'ditem ')
            divitem.setAttribute('id', item[0])

            const div2 = document.createElement("div");
            div2.setAttribute('class', 'col-md-12 col-sm-12 col-xs-12')
            div2.setAttribute('name', 'div2')

            const div3 = document.createElement("div");
            div3.setAttribute('class', 'info-box')
            div3.setAttribute('name', 'div3')

            const span3 = document.createElement("span");
            span3.setAttribute('class', 'info-box-icon bg-aqua')
            span3.setAttribute('name', 'span3')

            const oImg = document.createElement("IMG");
            oImg.setAttribute('src', IcoPdf);
            oImg.setAttribute('alt', 'na');
            oImg.setAttribute('height', '30px');
            oImg.setAttribute('width', '30px');

            span3.appendChild(oImg);
            div3.appendChild(span3);

            const div4 = document.createElement("div");
            div4.setAttribute('class', 'info-box-content-bgg');

            const span4 = document.createElement("span");
            //span4.setAttribute('class','');
            const txtp = nomeAta(item[0]);
            const node = document.createTextNode(txtp);
            span4.appendChild(node);

            div4.appendChild(span4);

            div3.appendChild(div4);
            div2.appendChild(div3);
            divRow.appendChild(div2);


            //cl('item',item);
            const paraA = document.createElement("a");
            const parabr = document.createElement("p");
            //   const paraI = document.createElement("i");
            //   paraI.setAttribute('class', 'far fa-file-word');

            //const linkrrl = bViewPdf(global.gCond,'ATAS',item[0])
            const pasta = "PD";
            const linkurl2 = "bViewPdf(\'" + global.gCond + '\',\'' + pasta + '\',\'' + item[0] + "\'); return false;";

            paraA.onClick = "console.log('teste');";
            paraA.target = "_blank";
            paraA.setAttribute('rel', 'noopener noreferrer');
            paraA.setAttribute('class', 'btn btn-block btn-social btn-facebook');
            //paraA.setAttribute('href', '');

            global.bgg2 = function (cond, pasta, file) {
              //console.log(cond,pasta,file);
              bViewPdf(cond, pasta, file);
              //return;
            }

            const linkclick = "bgg2(\'" + global.gCond + '\',\'' + pasta + '\',\'' + item[0] + "\')";
            paraA.setAttribute('onClick', linkclick);
            //paraA.appendChild(oImg);
            paraA.appendChild(divRow);

            if (5 < 1) {

            } else {
              const btn_aprovar = document.createElement("button");
              const btn_ressalva = document.createElement("button");
              btn_aprovar.setAttribute('class', 'btn btn-info btn-block btnbggp');
              btn_ressalva.setAttribute('class', 'btn btn-warning btn-block btnbggp');
              //let id_btn = 'btn_aprovar'+item["id"];
              let id_btn = 'btn_aprovar';
              let id_btn2 = 'btn_ressalva';
              //cl("id_btn1",id_btn) ;          
              btn_aprovar.setAttribute('id', id_btn);
              btn_ressalva.setAttribute('id', id_btn2);
              btn_aprovar.setAttribute('type', 'button');
              btn_ressalva.setAttribute('type', 'button');
              //const click_btn_aprovar = "whatsappweb()";
              //btn_aprovar.setAttribute('onClick', click_btn_aprovar);
              //cl("clrespFilesAprov",global.grespFilesAprov);
              //cl("clitem",item[0]);

              btn_aprovar.innerHTML = 'Aprovar' + nomeBtnAprovar(item[0]);
              btn_ressalva.innerHTML = 'Enviar Ressalva' + nomeBtnAprovar(item[0]);

              btn_aprovar.addEventListener("click", function () {
                let msgarq = "Arquivo : " + item0nome;
                let msgdata = "ultima alteração em " + moment(item1data, 'YYYY/MM/DD').format('DD/MM/YYYY');
                let msgsize = "tamanho " + item1size + " bytes";
                //alert(msg);
                confirmAlert({
                  closeOnClickOutside: false,
                  customUI: ({ onClose }) => {
                    return (

                      <div className='custom-ui'>
                        {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
                        <h3>Aprovação Digital</h3><br></br><br></br>
                        <p id='pmodal'><b>"Confirme os dados abaixo !"</b></p>
                        <p id='pmodal'><b>Registro do arquivo</b></p>
                        <p id='pmodal'>{msgarq}</p>
                        <p id='pmodal'>{msgdata}</p>
                        <p id='pmodal'>{msgsize}</p>
                        <p id='pmodal'><b>Seus dados</b></p>
                        <p id='pmodal'>{global.gNome}</p>
                        <p id='pmodal'>{global.gEmail}</p>
                        <p id='pmodal'>Seu ip é {global.gIp}</p>
                        <br></br>
                        <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
                        <button
                          className="btn bg-gradient-success btn-sm float-right"
                          onClick={() => {
                            //alert('Em desenvolvimento no backend/servidor');
                            global.nomeFileAprov = item0nome;
                            setAprov(
                              {
                                'nomefile': item0nome,
                                'bytestxt': item1size
                              });
                            onClose();
                          }}
                        >
                          Sim, aprovar!
                        </button>
                      </div>
                    );
                  }
                });
                return;
              });

              btn_ressalva.addEventListener("click", function () {
                let msgarq = "Arquivo : " + item0nome;
                let msgdata = "ultima alteração em " + moment(item1data, 'YYYY/MM/DD').format('DD/MM/YYYY');
                let msgsize = "tamanho " + item1size + " bytes";
                //alert(msg);
                confirmAlert({
                  closeOnClickOutside: false,
                  customUI: ({ onClose }) => {
                    return (

                      <div className='custom-ui'>
                        {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
                        <h3>Comunicar Ressalva</h3><br></br><br></br>
                        <p id='pmodal'><b>"Confirme os dados abaixo !"</b></p>
                        <p id='pmodal'><b>Registro do arquivo</b></p>
                        <p id='pmodal'>{msgarq}</p>
                        <p id='pmodal'><b>Seus dados</b></p>
                        <div className="form-group">
                          <label>Descreva sua ressalva :</label>
                          <textarea id="ta1" className="form-control" rows={10} placeholder="Enter ..." defaultValue={""} maxLength={"1000"} />


                        </div>

                        <br></br>
                        <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Cancelar</button>
                        <button
                          className="btn bg-gradient-warning btn-sm float-right"
                          onClick={() => {
                            //alert('Em desenvolvimento no backend/servidor');
                            let eta1 = document.getElementById('ta1');
                            if(eta1){

                              if(eta1.value.length < 5) {
                                alert('Precisa preencher a descrição ! ')
                                eta1.focus();
                                return
                              }

                            } else {
                              return
                            }

                            global.nomeFileAprov = item0nome;
                            global.gMsgRessalva = eta1.value;
                            setRessalva(
                              {
                                'nomefile': item0nome,
                                'bytestxt': item1size
                              });
                            onClose();
                          }}
                        >
                          Enviar!
                        </button>
                      </div>
                    );
                  }
                });
                return;
              });

              global.gfilesfound1 = global.grespFilesAprov.filter(function (key, num) {
                return ((key.nomefile === item[0]) && (key.cond === global.gCond) && (key.aprovado === '1'));
              });
              global.gressalvafound1 = global.grespFilesAprov.filter(function (key, num) {
                return ((key.nomefile === item[0]) && (key.cond === global.gCond) && (key.aprovado === '2'));
              });


              const divbtn_ressalva = document.createElement("div");
              divbtn_ressalva.setAttribute('id', 'divbtn_ressalva');

              if (global.gfilesfound1.length > 0) {
                const divp_aprovado = document.createElement("div");
                divp_aprovado.setAttribute('id', 'divpIdaprov');
                const p_aprovado = document.createElement("p");
                p_aprovado.setAttribute('class', 'btn btn-success btn-block btnbggp');
                p_aprovado.setAttribute('id', 'pIdaprov');
                p_aprovado.innerHTML = "Aprovou " + moment(global.gfilesfound1[0].createdAt, 'YYYY-MM-DD HH:mm:ss').add(-3, 'hours').format('DD/MM/YYYY HH:mm') + "h<br> IP " + global.gfilesfound1[0].ip

                divp_aprovado.appendChild(p_aprovado)

                divdbtn.appendChild(divp_aprovado);

              } else {
                //cl("clglobal.gfilesfound", global.gfilesfound1);
                divdbtn.appendChild(btn_aprovar);
                divbtn_ressalva.appendChild(btn_ressalva);
                divdbtn.appendChild(divbtn_ressalva);
              }
              if (global.gressalvafound1.length > 0) {

                global.gressalvafound1.forEach(function (val) {
                  const p_ressalva = document.createElement("p");
                  p_ressalva.setAttribute('id', 'p_ressalva');
                  p_ressalva.innerHTML = "Ressalva protocolo " + moment(val.createdAt, 'YYYY-MM-DD HH:mm:ss').add(-3, 'hours').format('YYHHDDMM') + val.id
                  p_ressalva.addEventListener("click", function () {
                    let msgarq = "Arquivo : " + item0nome;
                    let msgdata = "ultima alteração em " + moment(item1data, 'YYYY/MM/DD').format('DD/MM/YYYY');
                    let dtenvio = moment(val.createdAt, 'YYYY-MM-DD HH:mm:ss').add(-3, 'hours').format('DD/MM/YYYY HH:mm');
                    let msgsize = "tamanho " + item1size + " bytes";
                    let msgvaldeco = decodeURIComponent(val.msg);
                    //alert(msg);
                    confirmAlert({
                      closeOnClickOutside: false,
                      customUI: ({ onClose }) => {
                        return (
    
                          <div className='custom-ui'>
                            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
                            <h3>Ressalva Enviada</h3><br></br><br></br>
                            <p id='pmodal'><b>Registro do arquivo</b></p>
                            <p id='pmodal'>{msgarq}</p>
                            <p id='pmodal'>enviada em {dtenvio}</p>
                            <div className="form-group">
                              <label>Descrição :</label>
                              <textarea id="ta1dis" className="form-control" disabled rows={10} placeholder="Enter ..." defaultValue={msgvaldeco} maxLength={"1000"} />
    
    
                            </div>
                            <p id='pmodal'>Entraremos em contato!</p>
    
                            <br></br>
                            <button
                              className="btn bg-gradient-success btn-sm float-right"
                              onClick={() => {
                                //alert('Em desenvolvimento no backend/servidor');
/*                                 global.nomeFileAprov = item0nome;
                                global.gMsgRessalva = document.getElementById('ta1').value;
                                setRessalva(
                                  {
                                    'nomefile': item0nome,
                                    'bytestxt': item1size
                                  }); */
                                onClose();
                              }}
                            >
                              OK!
                            </button>
                          </div>
                        );
                      }
                    });
                    return;
                  });
                  divdbtn.appendChild(p_ressalva);
                })
              }

            }
            divdfile.appendChild(paraA);
            divitem.appendChild(divdfile);
            if ((moment(new Date().getTime()).diff(moment(arrItem1[0], "YYYY/MM/DD"), 'days')) < 60) {
              //cl("diferença dias", moment(new Date().getTime()).diff(moment(item[1], "YYYY/MM/DD"), 'days'));
              divitem.appendChild(divdbtn);
              /*               if (global.gfilesfound1.length !== 0) {
                            } */

            }


            //parabr.appendChild(paraA);
            element.appendChild(divitem);

          });

        } else {

          txtTxtSel("Nenhuma Ata disponível !");

        }
        let eleCardbody = document.getElementById('cardGridBggBody');
        if (eleCardbody) { eleCardbody.style.display = ''; }

      };

      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        //setListProp({ 'chave': chavedel });
        //cl('setatu 01 01')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [ViewPdf])

  function txtTxtSel(a) {
    let txtC = document.getElementById('TxtSeltxt');
    txtC.textContent = a;
  }
  //add050523
  function validaSelectCond(params) {

    setSelGCond({ '0': '0' });

  }
  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }


  function copiarlinha(linha) {
    copycli(linha, {
      debug: true,
      message: 'Press #{key} to copy',
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Linha digitável copiada !!</h1><br></br><br></br>
            <p id='pmodal'><b>{linha}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Ok</button>
          </div>
        );
      }
    });
    //clipboardy.writeSync(linha);
  }


  const printIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-print'></i>";
  };
  const iconwhatsappweb = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-whatsapp'></i>";
  }
  const iconcopy = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-copy'></i>";
  }
  const iconLixeira = function (cell, formatterParams) { //plain text value
    if (cell.getRow().getData().progress < 11) {
      return "<i class='fa fa-trash-o'></i>";
    } else {
      return "-";
    }
  }
  const emailIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-envelope-o'></i>";
  };
  const infoIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-info-circle'></i> " + cell.getRow().getData().msginfo;
  };
  const wordIcon = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-file-word'></i>";
  };
  const iconContrato = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-plus-square'></i>";
  };
  const iconBarcode = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-eye'></i>";
  };

  const formatPrazo = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getValue(), "dd/mm/yyyy"));
    if (cell.getValue() == null) {
      return "-"; //return the contents of the cell;  
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias < 3)) {
      return "<font color='red'><b>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</b></font>";
    } else if ((cell.getRow().getData().qtdProp > 0)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias > 3)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    }
  }

  const formatTdifdias = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getRow().getData().prazo, "dd/mm/yyyy"));
    if ((cell.getRow().getData().qtdProp < 1) && (cell.getRow().getData().prazo !== null)) {
      if (tdifdias >= 4) {
        return "<font color='green'>" + tdifdias + "</font>";
      } else if ((tdifdias > 0) && (tdifdias <= 3)) {
        return "<font color='red'>" + tdifdias + "</font>";
      } else if ((tdifdias <= 0)) {
        return "<font color='red'><b>" + tdifdias + "</b></font>";
      }
    } else {
      return "";
    }

  }



  if (rep.length < 1) {
    return (
      <div className="content-wrapper">
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando dados...</div>
      </div>
    )
  } else {
    return (
      <div className="content-wrapper">
        {ViewDocs()}
        {/* {page1()} */}
        {/* {page2()} */}
        {/* {page3()} */}
        {/* {page4()} */}
        {/* {page5()} */}
      </div>
    )

  }
}

export default Gerador